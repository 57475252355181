import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Img from 'gatsby-image'
import ctaVideo from '../videos/cta.mp4'
import Vendor from '../components/vendor/vendor'

class CharityPartners extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Charity Partners"
          description="How we’re associated with Heads Together and Ruth Strauss Foundation"
        />
        <article>
          <div className="c-masthead c-masthead--center">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Charity Partners</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="c-charity-listing c-charity-listing--nomargin-top">
            <div className="container">
              <div className="row">
                <div className="c-charity-listing__item">
                  <div className="c-charity-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.headsTogether.childImageSharp.fluid
                      }
                      alt="Heads Together"
                    />
                  </div>
                  <div className="c-charity-listing__item-content-container">
                    <h2 className="c-charity-listing__item-title">
                      Heads Together
                    </h2>
                    <p className="c-charity-listing__item-summary">
                      Through our work with young people, emergency response, homeless charities, and with veterans,
                      we have seen time and time again that unresolved mental health problems lie at the heart of some of our greatest social challenges.
                    </p>
                    <a href="https://www.headstogether.org.uk" className="c-charity-listing__link c-btn" target="_blank" rel="nofollow">Visit Charity</a>
                  </div>
                </div>
                <div className="c-charity-listing__item">
                  <div className="c-charity-listing__item-media-container">
                    <Img
                      fluid={
                        this.props.data.ruthStrauss.childImageSharp.fluid
                      }
                      alt="Ruth Strauss Foundation"
                    />
                  </div>
                  <div className="c-charity-listing__item-content-container">
                    <h2 className="c-charity-listing__item-title">
                      Ruth Strauss Foundation
                    </h2>
                    <p className="c-charity-listing__item-summary">
                      Rare forms of lung cancer can affect anyone at any age, including those who have never smoked.
                      The Ruth Strauss foundation provides research grants and support to patients and their families.
                    </p>
                    <a href="https://ruthstraussfoundation.com" className="c-charity-listing__link c-btn" target="_blank" rel="nofollow">Visit Charity</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-cta-content">
            <div className="container">
              <div className="row">
                <div className="c-cta-content__title-container">
                  <h2 className="c-cta-content__title">How we’re associated with Heads Together.</h2>
                </div>
                <div className="c-cta-content__content-container">
                  <div className="cms-content">
                    <p>
                      In support of Heads Together 10% of the Producers’ Net Profits from The Edge Film will be paid to The Royal Foundation (registered charity no. 1132048)
                      to support the charity’s work, including its Heads Together mental health work. We are expecting to raise a minimum of £30,000 for the charity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Vendor className="c-vendor--inpage-light" />
          <div className="c-cta c-cta--nomargin-top c-cta--nomargin-bottom">
            <video
              src={ctaVideo}
              className="c-cta__video"
              autoPlay muted playsInline loop>
            </video>
            <div className="container">
              <div className="row">
                <div className="c-cta__link-container">
                  <Link className="c-cta__link" to="/merchandise">Merchandise</Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const fluidImage = graphql`
  fragment charityImage on File {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    ruthStrauss: file(relativePath: { eq: "charitypartners/ruth-strauss.jpg" }) {
      ...charityImage
    }
    headsTogether: file(relativePath: { eq: "charitypartners/heads-together.jpg" }) {
      ...charityImage
    }
  }
`

export default CharityPartners
